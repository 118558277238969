import React from 'react';

const TeamsPage = () => {
  return (
    <div>
      <h1>Teams</h1>
      <p>View and manage teams.</p>
    </div>
  );
};

export default TeamsPage;
